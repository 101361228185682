import * as React from "react";
import withStyles from "@mui/styles/withStyles";
import { AppBar as MuiAppBar, Toolbar, Grid } from "@mui/material";
import { Loader } from "@talentpair/quantic";
import { ErrorBoundary } from "@talentpair/sentry";

export const Content = withStyles({
  item: {
    display: "flex",
    height: "calc(100vh - 56px)",
    marginTop: 56,
    "@media (min-width:0px) and (orientation: landscape)": {
      height: "calc(100vh - 48px)",
      marginTop: 48,
    },
    "@media (min-width:600px)": {
      height: "calc(100vh - 64px)",
      marginTop: 64,
    },
  },
})(Grid);

type AppBarProps = {
  leftAction?: React.ReactNode;
  rightAction?: React.ReactNode;
  logo?: React.ReactNode;
};
export function AppBar({ leftAction, rightAction, logo }: AppBarProps): React.ReactElement {
  return (
    <MuiAppBar elevation={0}>
      <Toolbar className="relative flex-center">
        <div className="flex-auto flex justify-between items-center">
          {leftAction || <div> </div>}
          {rightAction || <div> </div>}
        </div>
        <div className="absolute flex-center">{logo}</div>
      </Toolbar>
    </MuiAppBar>
  );
}

export const AppPage = ({
  children,
  ...props
}: React.PropsWithChildren<AppBarProps>): React.ReactElement => (
  <Grid>
    <AppBar {...props} />
    <Content item direction="column" container>
      {children}
    </Content>
  </Grid>
);

const AppPageLoader = (): React.ReactElement => (
  <AppPage>
    <Loader absolute />
  </AppPage>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface AppPageWrapperProps extends Record<string, any> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  page: React.LazyExoticComponent<any>;
}
export function AppPageWrapper({ page: Page, ...props }: AppPageWrapperProps): React.ReactElement {
  return (
    <ErrorBoundary>
      <React.Suspense fallback={<AppPageLoader />}>
        <Page {...props} />
      </React.Suspense>
    </ErrorBoundary>
  );
}
