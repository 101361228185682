import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { NavigationAnalytics } from "@talentpair/tracking";
import { tpTheme } from "@talentpair/quantic";
import { PortalRenderer } from "@talentpair/portal";
import { ErrorBoundary, SentryRoute, withProfiler } from "@talentpair/sentry";
import * as React from "react";
import { History } from "history";
import { Helmet } from "react-helmet";
import { Router, Redirect, Switch } from "@talentpair/routing";
import AppVersionPing from "kyoto/components/AppVersionPing";
import TalentpairAssistantChat from "./pages/TalentpairAssistantChat";
import SecretAgent from "./pages/SecretAgent";

const routes = new Map([
  [
    "TalentpairAssistant/Home",
    { key: "TalentpairAssistant/Home", path: "/:sessionid?", exact: true },
  ],
]);

interface PropsT {
  history: History;
}

function App({ history }: PropsT): React.ReactElement {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={tpTheme}>
        <Router history={history}>
          <ErrorBoundary>
            <Helmet>
              <title>Talentpair Assistant</title>
            </Helmet>
            <CssBaseline />
            <Switch>
              {["local", "gojira"].includes(process.env.HOST) ? (
                <SentryRoute path="/secret-agent" exact component={SecretAgent} />
              ) : null}
              <SentryRoute
                {...routes.get("TalentpairAssistant/Home")}
                render={({ match }) => (
                  <TalentpairAssistantChat sessionid={match.params.sessionid?.trim() || null} />
                )}
              />
              <Redirect to="/" />
            </Switch>
            <AppVersionPing app="perryai" />
            <PortalRenderer />
            <NavigationAnalytics routes={routes} />
          </ErrorBoundary>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default withProfiler(App);
